import _highLevelOpt from "./high-level-opt.js";
import _replace from "./replace.js";
var exports = {};
// tar -u
const hlo = _highLevelOpt;
const r = _replace; // just call tar.r with the filter and mtimeCache

const u = exports = (opt_, files, cb) => {
  const opt = hlo(opt_);
  if (!opt.file) throw new TypeError("file is required");
  if (opt.gzip) throw new TypeError("cannot append to compressed archives");
  if (!files || !Array.isArray(files) || !files.length) throw new TypeError("no files or directories specified");
  files = Array.from(files);
  mtimeFilter(opt);
  return r(opt, files, cb);
};

const mtimeFilter = opt => {
  const filter = opt.filter;
  if (!opt.mtimeCache) opt.mtimeCache = new Map();
  opt.filter = filter ? (path, stat) => filter(path, stat) && !(opt.mtimeCache.get(path) > stat.mtime) : (path, stat) => !(opt.mtimeCache.get(path) > stat.mtime);
};

export default exports;