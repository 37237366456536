import _highLevelOpt from "./high-level-opt.js";
import _pack from "./pack.js";
import * as _fs2 from "fs";

var _fs = "default" in _fs2 ? _fs2.default : _fs2;

import * as _fsMinipass2 from "fs-minipass";

var _fsMinipass = "default" in _fsMinipass2 ? _fsMinipass2.default : _fsMinipass2;

import _list from "./list.js";
import * as _path2 from "path";

var _path = "default" in _path2 ? _path2.default : _path2;

var exports = {};
// tar -c
const hlo = _highLevelOpt;
const Pack = _pack;
const fs = _fs;
const fsm = _fsMinipass;
const t = _list;
const path = _path;

const c = exports = (opt_, files, cb) => {
  if (typeof files === "function") cb = files;
  if (Array.isArray(opt_)) files = opt_, opt_ = {};
  if (!files || !Array.isArray(files) || !files.length) throw new TypeError("no files or directories specified");
  files = Array.from(files);
  const opt = hlo(opt_);
  if (opt.sync && typeof cb === "function") throw new TypeError("callback not supported for sync tar functions");
  if (!opt.file && typeof cb === "function") throw new TypeError("callback only supported with file option");
  return opt.file && opt.sync ? createFileSync(opt, files) : opt.file ? createFile(opt, files, cb) : opt.sync ? createSync(opt, files) : create(opt, files);
};

const createFileSync = (opt, files) => {
  const p = new Pack.Sync(opt);
  const stream = new fsm.WriteStreamSync(opt.file, {
    mode: opt.mode || 438
  });
  p.pipe(stream);
  addFilesSync(p, files);
};

const createFile = (opt, files, cb) => {
  const p = new Pack(opt);
  const stream = new fsm.WriteStream(opt.file, {
    mode: opt.mode || 438
  });
  p.pipe(stream);
  const promise = new Promise((res, rej) => {
    stream.on("error", rej);
    stream.on("close", res);
    p.on("error", rej);
  });
  addFilesAsync(p, files);
  return cb ? promise.then(cb, cb) : promise;
};

const addFilesSync = (p, files) => {
  files.forEach(file => {
    if (file.charAt(0) === "@") t({
      file: path.resolve(p.cwd, file.substr(1)),
      sync: true,
      noResume: true,
      onentry: entry => p.add(entry)
    });else p.add(file);
  });
  p.end();
};

const addFilesAsync = (p, files) => {
  while (files.length) {
    const file = files.shift();
    if (file.charAt(0) === "@") return t({
      file: path.resolve(p.cwd, file.substr(1)),
      noResume: true,
      onentry: entry => p.add(entry)
    }).then(_ => addFilesAsync(p, files));else p.add(file);
  }

  p.end();
};

const createSync = (opt, files) => {
  const p = new Pack.Sync(opt);
  addFilesSync(p, files);
  return p;
};

const create = (opt, files) => {
  const p = new Pack(opt);
  addFilesAsync(p, files);
  return p;
};

export default exports;