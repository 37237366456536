import _create from "./lib/create.js";
import _replace from "./lib/replace.js";
import _list from "./lib/list.js";
import _update from "./lib/update.js";
import _extract from "./lib/extract.js";
import _pack from "./lib/pack.js";
import _unpack from "./lib/unpack.js";
import _parse from "./lib/parse.js";
import _readEntry from "./lib/read-entry.js";
import _writeEntry from "./lib/write-entry.js";
import _header from "./lib/header.js";
import _pax from "./lib/pax.js";
import _types from "./lib/types.js";
var exports = {};
// high-level commands
exports.c = exports.create = _create;
exports.r = exports.replace = _replace;
exports.t = exports.list = _list;
exports.u = exports.update = _update;
exports.x = exports.extract = _extract; // classes

exports.Pack = _pack;
exports.Unpack = _unpack;
exports.Parse = _parse;
exports.ReadEntry = _readEntry;
exports.WriteEntry = _writeEntry;
exports.Header = _header;
exports.Pax = _pax;
exports.types = _types;
export default exports;
export const c = exports.c,
      create = exports.create,
      r = exports.r,
      replace = exports.replace,
      t = exports.t,
      list = exports.list,
      u = exports.u,
      update = exports.update,
      x = exports.x,
      extract = exports.extract,
      Pack = exports.Pack,
      Unpack = exports.Unpack,
      Parse = exports.Parse,
      ReadEntry = exports.ReadEntry,
      WriteEntry = exports.WriteEntry,
      Header = exports.Header,
      Pax = exports.Pax,
      types = exports.types;